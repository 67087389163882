
import { DiagramConnection, DiagramObject, findModelObjectsInScope, ModelConnection, ModelObject, PermissionType, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { AlertModule } from '@/modules/alert/store'
import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'

@Component({
  name: 'ModelObjectName'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly nameField!: HTMLElement

  @Prop() readonly object!: DiagramObject | DiagramConnection
  @Prop() readonly modelObject!: ModelObject | ModelConnection
  @Prop() readonly permission!: PermissionType

  model = ''
  editing = false

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get modelObjectName () {
    return this.modelObject.name
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  @Watch('modelObjectName')
  onModelObjectNameChanged (newVal?: string) {
    this.model = newVal || ''
  }

  mounted () {
    this.model = this.modelObject.name || ''
  }

  blurField () {
    if (this.permission !== 'read') {
      this.editing = false
      this.ensureConnectionName(this.model)
    }
  }

  enterKey (event: KeyboardEvent) {
    if (!event.shiftKey) {
      event.preventDefault()
      this.nameField.blur()
    }
  }

  escapeKey () {
    this.model = this.modelObject.name || ''
    this.nameField.blur()
  }

  startEditing () {
    if (this.permission !== 'read') {
      this.editing = true
    }
  }

  updateName (name: string) {
    const obj = this.modelObject
    if (obj.name !== name) {
      if ('type' in obj) {
        this.updateObjectName(obj, name)
      } else {
        this.updateConnectionName(obj, name)
      }
    }
  }

  updateObjectName (obj: ModelObject, name: string) {
    const modelExists = findModelObjectsInScope(this.modelModule.objects, obj.id)
      .find(o => o.id !== obj.id && o.name.toLowerCase().trim() === name.toLowerCase().trim())
    if (modelExists) {
      this.alertModule.pushAlert({
        color: 'error',
        message: `${name} already exists at this level, please use a unique name.`
      })
    } else {
      const prevObject = window.structuredClone(obj)

      const tasks: Task[] = []
      const revertTasks: Task[] = []

      if (this.currentDiagram?.status === 'draft') {
        revertTasks.push({
          id: this.currentDiagram.id,
          props: {
            tasksProposed: {
              $append: [{
                id: obj.id,
                props: {
                  name: obj.name
                },
                type: 'model-object-update'
              }]
            }
          },
          type: 'diagram-content-update'
        }, {
          route: this.$route,
          type: 'navigation'
        })

        const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, {
          tasksProposed: {
            $append: [{
              id: obj.id,
              props: {
                name
              },
              type: 'model-object-update'
            }]
          }
        })
        this.diagramModule.setDiagramContentVersion(diagramContent)
        this.editorModule.addToTaskQueue({
          func: () => this.diagramModule.diagramContentUpdate({
            diagramId: diagramContent.id,
            landscapeId: this.currentLandscape.id,
            props: diagramContentUpdate,
            versionId: this.currentVersion.id
          })
        })

        tasks.push({
          id: diagramContent.id,
          props: diagramContentUpdate,
          type: 'diagram-content-update'
        })
      } else {
        revertTasks.push({
          id: obj.id,
          props: {
            name: obj.name
          },
          type: 'model-object-update'
        }, {
          route: this.$route,
          type: 'navigation'
        })

        const { object, objectUpdate } = this.modelModule.generateObjectCommit(obj.id, { name })
        this.modelModule.setObjectVersion(object)
        this.editorModule.addToTaskQueue({
          func: () => this.modelModule.objectUpdate({
            landscapeId: this.currentLandscape.id,
            objectId: object.id,
            props: objectUpdate,
            versionId: this.currentVersion.id
          })
        })

        tasks.push({
          id: object.id,
          props: objectUpdate,
          type: 'model-object-update'
        })

        analytics.modelObjectUpdate.track(this, {
          landscapeId: [this.currentLandscape.id],
          modelObjectDescriptionLength: prevObject.description?.length || 0,
          modelObjectDiagramCount: Object.keys(prevObject.diagrams).length,
          modelObjectExternal: prevObject.external,
          modelObjectIconName: prevObject.icon?.name || null,
          modelObjectLinkCount: Object.keys(prevObject.links).length,
          modelObjectNameLength: prevObject.name.length,
          modelObjectParent: prevObject.parentId,
          modelObjectStatus: prevObject.status,
          modelObjectTagCount: prevObject.tagIds.length,
          modelObjectTeamOnlyEditing: prevObject.teamOnlyEditing,
          modelObjectTechnologyCount: Object.keys(prevObject.technologies).length,
          modelObjectTechnologyNames: Object.values(prevObject.technologies).map(o => o.name),
          modelObjectType: prevObject.type,
          modelObjectUpdateNameLength: name.length || undefined,
          organizationId: [this.currentLandscape.organizationId]
        })
      }

      tasks.push({
        route: this.$route,
        type: 'navigation'
      })

      this.editorModule.addTaskList({
        revertTasks,
        tasks
      })
    }
  }

  updateConnectionName (con: ModelConnection, name: string) {
    const prevConnection = window.structuredClone(con)

    const tasks: Task[] = []
    const revertTasks: Task[] = []

    if (this.currentDiagram?.status === 'draft') {
      revertTasks.push({
        id: this.currentDiagram.id,
        props: {
          tasksProposed: {
            $append: [{
              id: con.id,
              props: {
                name: con.name
              },
              type: 'model-connection-update'
            }]
          }
        },
        type: 'diagram-content-update'
      }, {
        route: this.$route,
        type: 'navigation'
      })

      const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, {
        tasksProposed: {
          $append: [{
            id: con.id,
            props: {
              name
            },
            type: 'model-connection-update'
          }]
        }
      })
      this.diagramModule.setDiagramContentVersion(diagramContent)
      this.editorModule.addToTaskQueue({
        func: () => this.diagramModule.diagramContentUpdate({
          diagramId: diagramContent.id,
          landscapeId: this.currentLandscape.id,
          props: diagramContentUpdate,
          versionId: this.currentVersion.id
        })
      })

      tasks.push({
        id: diagramContent.id,
        props: diagramContentUpdate,
        type: 'diagram-content-update'
      })
    } else {
      revertTasks.push({
        id: con.id,
        props: {
          name: con.name
        },
        type: 'model-connection-update'
      }, {
        route: this.$route,
        type: 'navigation'
      })

      const { connection, connectionUpdate } = this.modelModule.generateConnectionCommit(con.id, {
        name
      })
      this.modelModule.setConnectionVersion(connection)
      this.editorModule.addToTaskQueue({
        func: () => this.modelModule.connectionUpdate({
          connectionId: connection.id,
          landscapeId: this.currentLandscape.id,
          props: connectionUpdate,
          versionId: this.currentVersion.id
        })
      })

      tasks.push({
        id: connection.id,
        props: connectionUpdate,
        type: 'model-connection-update'
      }, {
        route: this.$route,
        type: 'navigation'
      })

      analytics.modelConnectionUpdate.track(this, {
        landscapeId: [this.currentLandscape.id],
        modelConnectionDescriptionLength: prevConnection.description?.length || 0,
        modelConnectionDirection: prevConnection.direction,
        modelConnectionNameLength: prevConnection.name.length,
        modelConnectionStatus: prevConnection.status,
        modelConnectionTechnologyCount: Object.keys(prevConnection.technologies).length,
        modelConnectionTechnologyNames: Object.values(prevConnection.technologies).map(o => o.name),
        modelConnectionUpdateDescriptionLength: connectionUpdate.description ? connectionUpdate.description.length : undefined,
        organizationId: [this.currentLandscape.organizationId]
      })

      this.editorModule.addTaskList({
        revertTasks,
        tasks
      })
    }
  }

  ensureConnectionName (name: string) {
    if (!name && !('type' in this.modelObject)) {
      this.updateConnectionName(this.modelObject, 'Sends data to')
    }
  }
}
